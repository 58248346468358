.preview-header {
  background-color: #262626;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Antd customs style  */

.container-outer {
  margin-top: 60px;
  margin-bottom: 60px;
}
.container-top {
  margin-top: 60px;
}
.container-bottom {
  margin-bottom: 60px;
}
@media screen and (min-width: 1024px) {
  .container-bottom {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1024px) {
  .container-outer {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.primary-accent {
  background-color: theme('colors.primaryaccent') !important;
}

.text-primary {
  color: theme('colors.primary');
}

.secondary-btn {
  background-color: theme('colors.sky') !important;
}

.primary-btn {
  background-color: #4EC5B6 !important;
  border-radius: 0px;
  color: white;
}

/* tab  */

.header-tab {
  padding: 1em 1.5em;
  display: flex;
  align-items: center;
  width: 342px;
}

.hero-tab .ant-tabs-ink-bar-animated {
  display: none;
}

@media screen and (min-width: 576px)and (max-width: 776px) {
  .header-tab {
    width: 150px;
    padding: 1em 0.5em;
  }
}

@media screen and (min-width: 776px)and (max-width: 1024px) {
  .header-tab {
    width: 190px;
  }
}
@media screen and (min-width: 1024px)and (max-width: 1400px) {
  .header-tab {
    width: 230px;
  }
}

@media screen and (min-width: 1400px)and (max-width: 1560px) {
  .header-tab {
    width: 280px;
  }
}


.hero-tab .ant-tabs-nav-list {
  flex-direction: row;
}

@media screen and (max-width: 576px) {
  .hero-tab .ant-tabs-nav-list {
    flex-direction: column;
  }
}

.hero-tab .ant-tabs-nav-wrap {
  justify-content: center;
  border: 0px;
}

.hero-tab .ant-tabs-tab {
  background-color: #f1f1f1;
  margin-left: 0px !important;
}

.hero-tab .ant-tabs-nav::before {
  border: none;
}

.hero-tab .ant-tabs-tab-active {
  background-color: #FEF3E3;
}

.hero-tab .ant-tabs-tab:hover {
  background-color: #C1E7B0;
}

.image-container {
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.showcase {
  /* margin: 0px 90px 0px 90px; */
  height: 600px;
  position: relative;
  color: white;
  text-align: center;
}

.overlay img {
  /* width: 40%; */
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
  margin-left: auto;
  margin-right: auto
}

.showcase .overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
}

.shadow {
  position: absolute;
}

.widget-container {
  position: absolute;
  z-index: 999;
}

.elementor-weight {
  position: absolute;
  z-index: 999;
}

.showcase p {
  position: absolute;
  top: 50%;
}

.outlet .ant-input {
  border-radius: 0px;
}

.outlet-btn {
  background-color: #4EC5B6 !important;
  padding: 30px 35px 30px 35px;
}

/* About card section css  */
/* #e2eeed */
.about-section .ant-card {
  box-shadow: none;
}

.about-section .card-widget {
  margin: -10% -15% 0% 10%;
  padding: 0px 43px 0px 87px;
}

@media screen and (max-width: 576px) {
  .about-section .card-widget {
    margin: -10% -15% 0% 0%;
    padding: 0px 43px 0px 70px;
  }
}

@media screen and (max-width: 992px) {
  .about-section .card-widget {
    margin: -10% -15% 0% 30%;
    padding: 0px 43px 0px 0px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .about-section .card-widget {
    margin: -10% -15% 0% 48%;
    padding: 0px 43px 0px 70px;
  }
}

/* margin: -15% -15% 0% 0% */

.about-section .side-widget img {
  max-width: 100%;
  transform: translate(60%, 0%);
  top: -60px;
}

@media screen and (max-width: 768px) {
  .about-section .side-widget img {
    transform: translate(40%, 0%);
  }
}

.bg-limelight {
  background-color: theme('colors.limelight');
}

.bg-lightorange {
  background-color: theme('colors.lightorange');
}

.about-section-img {
  display: flex;
  justify-content: end;
}

/* our product  */

.apple {
  background: linear-gradient(to bottom, #FC906F, #FBDBC7);
}

.orange {
  background: linear-gradient(to bottom, #FDD77D, #FBECC9);
}

.berry {
  background: linear-gradient(to bottom, #FC906F, #FBDBC7);
}

.banana {
  background: linear-gradient(to bottom, #FDD77D, #FBECC9);
}

.avocando {
  background: linear-gradient(to bottom, #AEDB8F, #DFF6D4);
}

.mango {
  background: linear-gradient(to bottom, #FAA967, #FBE6CC);
}

.icon-widget {
  background: linear-gradient(to bottom, rgba(249, 238, 178, 0.6), rgba(255, 255, 255, 0.6));
}

.filter-blur {
  filter: blur(8px);
}

/* Newsletter  */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-20%, -50%);
}

.news-letter-weidget {
  transform: translate(-90%, 10%);
}

@media screen and (max-width: 1200px) {
  .news-letter-weidget {
    transform: translate(-70%, -10%);
  }
}


.container-1 {
  width: 100%;
  padding-right: 20px !important;
  padding-left: 20px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 640px) {
  .container-1 {
    padding-right: 15px;
    padding-left: 15px;
    max-width: 610px;
  }
}

@media (min-width: 768px) {
  .container-1 {
    max-width: 728px;
  }
}

@media (min-width: 1024px) {
  .container-1 {
    max-width: 974px;
  }
}

@media (min-width: 1280px) {
  .container-1 {
    max-width: 1180px;
  }
}

@media (min-width: 1536px) {
  .container-1 {
    max-width: 1323px;
  }
}

/* breadcrumb image  */

.bg_image {
  background-image: url('../src/assets/Contactpage.png');
  background-repeat: no-repeat;
}

/* events  */

.event-tab .ant-tabs-tab-btn {
  color: white !important;
}

.event-tab .ant-tabs-tab:hover {
  background-color: #6f9a3795 !important;
}


.event-tab .ant-tabs-tab {
  background-color: #6f9a37 !important;
  width: 140px;
  justify-content: center;
}

.event-tab .ant-tabs-tab-active {
  background-color: #6f9a3795 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}